import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, vShow as _vShow } from "vue"
import _imports_0 from '@/assets/riverty-secondary-badge.svg'
import _imports_1 from '@/assets/checkmark.svg'
import _imports_2 from '../../assets/exclamation_mark.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row px-2 px-sm-0" }
const _hoisted_4 = { class: "col-12 px-sm-0 py-4" }
const _hoisted_5 = { class: "r-font-family-heading r-font-size-600 r-font-weight-light mt-2" }
const _hoisted_6 = { class: "mb-5" }
const _hoisted_7 = { class: "container" }
const _hoisted_8 = {
  key: 0,
  class: "row"
}
const _hoisted_9 = { class: "col-12 col-lg-8 px-0 d-flex justify-content-end" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12 col-lg me-lg-3 order-2 order-lg-1" }
const _hoisted_12 = { class: "row bg-white mt-3 mt-lg-0 px-2 py-4" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "r-font-size-500 mb-3" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col ps-0" }
const _hoisted_17 = { class: "r-font-size-400 r-font-weight-semibold" }
const _hoisted_18 = { class: "r-font-size-300" }
const _hoisted_19 = {
  key: 0,
  class: "row bg-white mt-3 px-2 pt-4 pb-2"
}
const _hoisted_20 = { class: "col-12" }
const _hoisted_21 = { class: "r-font-family-heading r-font-size-600 r-font-weight-light mb-3" }
const _hoisted_22 = { class: "r-font-size-400 mb-4" }
const _hoisted_23 = {
  key: 0,
  class: "r-font-size-200 w-100"
}
const _hoisted_24 = ["placeholder"]
const _hoisted_25 = {
  key: 1,
  class: "r-font-size-200 w-100"
}
const _hoisted_26 = ["placeholder", "oninput"]
const _hoisted_27 = {
  key: 2,
  class: "r-font-size-200 w-100"
}
const _hoisted_28 = ["data-maska"]
const _hoisted_29 = {
  key: 3,
  class: "r-font-size-200 w-100 position-relative"
}
const _hoisted_30 = ["data-maska", "placeholder"]
const _hoisted_31 = {
  key: 0,
  class: "check-iban",
  src: _imports_1,
  alt: "checkmark"
}
const _hoisted_32 = {
  key: 4,
  class: "r-font-size-200 w-100"
}
const _hoisted_33 = {
  key: 5,
  class: "r-font-size-200 w-100"
}
const _hoisted_34 = ["placeholder"]
const _hoisted_35 = { class: "row bg-white mt-3 px-2 py-4" }
const _hoisted_36 = { class: "col-12" }
const _hoisted_37 = { class: "r-font-family-heading r-font-size-600 r-font-weight-light mb-3" }
const _hoisted_38 = {
  key: 0,
  class: "col-12 mb-3"
}
const _hoisted_39 = { class: "r-font-size-200" }
const _hoisted_40 = ["innerHTML"]
const _hoisted_41 = { class: "col-12" }
const _hoisted_42 = { class: "r-font-size-200" }
const _hoisted_43 = { class: "col-12 col-lg-6" }
const _hoisted_44 = { class: "r-font-size-200 r-color-grey-charcoal-300" }
const _hoisted_45 = ["innerHTML"]
const _hoisted_46 = { class: "col-12 mb-4" }
const _hoisted_47 = { class: "row" }
const _hoisted_48 = { class: "col-12 col-lg-6" }
const _hoisted_49 = { class: "r-font-size-200 r-color-grey-charcoal-300" }
const _hoisted_50 = ["innerHTML"]
const _hoisted_51 = { class: "col-12 text-center text-lg-start col-lg-auto" }
const _hoisted_52 = { class: "col-12 col-lg-4 order-1 order-lg-2" }
const _hoisted_53 = { class: "row bg-white px-2 py-4" }
const _hoisted_54 = { class: "col-12" }
const _hoisted_55 = { class: "r-font-size-600 r-font-weight-semibold mb-4" }
const _hoisted_56 = { class: "r-font-size-600 r-font-weight-semibold" }
const _hoisted_57 = { class: "col-12" }
const _hoisted_58 = { class: "row h-100 align-items-center" }
const _hoisted_59 = { class: "col" }
const _hoisted_60 = { class: "col-auto" }
const _hoisted_61 = {
  key: 0,
  class: "row"
}
const _hoisted_62 = { class: "col-12" }
const _hoisted_63 = { class: "row h-100 align-items-center" }
const _hoisted_64 = { class: "col" }
const _hoisted_65 = { class: "col-auto" }
const _hoisted_66 = {
  key: 1,
  class: "row mt-3"
}
const _hoisted_67 = { class: "col-12" }
const _hoisted_68 = { class: "r-font-weight-semibold" }
const _hoisted_69 = { class: "col-12 mt-3" }
const _hoisted_70 = { class: "r-font-weight-semibold" }
const _hoisted_71 = { class: "container" }
const _hoisted_72 = { class: "row px-2 px-sm-0" }
const _hoisted_73 = { class: "col-12 px-sm-0 pb-3 pt-5 mt-5 pt-lg-3 mt-lg-0" }
const _hoisted_74 = { class: "r-font-size-200" }
const _hoisted_75 = {
  key: 0,
  class: "container p-0"
}
const _hoisted_76 = { class: "row pb-3" }
const _hoisted_77 = { class: "col-12" }
const _hoisted_78 = { class: "r-font-family-heading r-font-size-600 r-font-weight-light mt-3 mb-4" }
const _hoisted_79 = ["innerHTML"]
const _hoisted_80 = { class: "col-12 text-center text-lg-start col-lg-auto" }
const _hoisted_81 = {
  key: 1,
  class: "container p-0"
}
const _hoisted_82 = { class: "row pb-3" }
const _hoisted_83 = { class: "col-12" }
const _hoisted_84 = { class: "r-font-family-heading r-font-size-600 r-font-weight-light my-4" }
const _hoisted_85 = { class: "r-font-size-500 mb-4" }
const _hoisted_86 = ["innerHTML"]
const _hoisted_87 = {
  key: 2,
  class: "container p-0"
}
const _hoisted_88 = { class: "row pb-3" }
const _hoisted_89 = { class: "col-12" }
const _hoisted_90 = { class: "r-font-family-heading r-font-size-600 r-font-weight-light my-4" }
const _hoisted_91 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_button = _resolveComponent("r-button")!
  const _component_PayInXComponent = _resolveComponent("PayInXComponent")!
  const _component_FixedInstallmentsComponent = _resolveComponent("FixedInstallmentsComponent")!
  const _component_r_checkbox = _resolveComponent("r-checkbox")!
  const _component_r_accordion_item = _resolveComponent("r-accordion-item")!
  const _component_r_accordion = _resolveComponent("r-accordion")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("img", {
                class: "r-icon-size-l mb-2",
                alt: "Riverty logo",
                src: "https://cdn.riverty.design/logo/riverty-logomark-green.svg"
              }, null, -1)),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getTranslation("CheckoutTitle")), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("main", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_ctx.data.legalInfoRequiredFieldsPerPayments?.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_r_button, {
                    variant: "blank",
                    mode: "normal",
                    type: "button",
                    icon: "arrow-left",
                    onClick: _ctx.handleBackToList
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTranslation("BackToListButton")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.getTranslation("PaymentTitle")), 1),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col-auto" }, [
                      _createElementVNode("img", {
                        height: "56",
                        width: "90",
                        src: _imports_0,
                        alt: "Riverty`s logo"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.data.orderDetails.paymentMethod.title), 1),
                      _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.data.orderDetails.paymentMethod.method), 1)
                    ])
                  ]),
                  (_ctx.data.orderDetails.paymentMethod.additionalData && _ctx.data.orderDetails.paymentMethod.type == _ctx.payInXType())
                    ? (_openBlock(), _createBlock(_component_PayInXComponent, {
                        key: 0,
                        payInX: _ctx.getPayInXObj(),
                        getTranslation: _ctx.getTranslation
                      }, null, 8, ["payInX", "getTranslation"]))
                    : _createCommentVNode("", true),
                  (_ctx.data.orderDetails.paymentMethod.additionalData && _ctx.data.orderDetails.paymentMethod.type == _ctx.installmentType())
                    ? (_openBlock(), _createBlock(_component_FixedInstallmentsComponent, {
                        key: 1,
                        ref: "fixedInstallmentsComponent",
                        paymentMethod: _ctx.data.orderDetails.paymentMethod,
                        getTranslation: _ctx.getTranslation
                      }, null, 8, ["paymentMethod", "getTranslation"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_ctx.data.orderDetails.requiredFields.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.getTranslation("VerifyTitle")), 1),
                      _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.getTranslation("VerifyDescription")), 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requiredFields.filter((x) => x.requiredField != _ctx.RequiredField[_ctx.RequiredField.TermsAndConditions]), (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "col-12 col-lg-7 mb-3"
                      }, [
                        _createElementVNode("span", null, [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("Input" + item.requiredField + "Label")) + " ", 1),
                          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-danger" }, "*", -1))
                        ]),
                        (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.BirthDate])
                          ? (_openBlock(), _createElementBlock("label", _hoisted_23, [
                              _withDirectives(_createElementVNode("input", {
                                class: _normalizeClass(["r-input", {
                                            check: _ctx.birthDateIsValid,
                                            'r-input-error': _ctx.birthDateInputError,
                                        }]),
                                "data-maska": "##/##/####",
                                placeholder: _ctx.getBirthDatePlaceholder(),
                                inputmode: "numeric"
                              }, null, 10, _hoisted_24), [
                                [_directive_maska, void 0, _ctx.birthDateMaskaOptions]
                              ]),
                              _createElementVNode("span", {
                                class: _normalizeClass(["hint", { 'hint-error': _ctx.birthDateInputError }])
                              }, _toDisplayString(_ctx.birthDateHintMessage), 3)
                            ]))
                          : (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.CompanyName])
                            ? (_openBlock(), _createElementBlock("label", _hoisted_25, [
                                _createElementVNode("input", {
                                  class: "r-input",
                                  placeholder: item.requiredField,
                                  oninput: (e) => _ctx.handleInput(e, item)
                                }, null, 8, _hoisted_26),
                                _createElementVNode("span", {
                                  class: _normalizeClass(["hint", { 'hint-error': false }])
                                }, _toDisplayString(_ctx.companyNameHintMessage), 1)
                              ]))
                            : (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.IdentificationNumber])
                              ? (_openBlock(), _createElementBlock("label", _hoisted_27, [
                                  _withDirectives(_createElementVNode("input", {
                                    class: _normalizeClass(["r-input", {
                                            'check-identification-number': _ctx.identificationNumberIsValid === true && _ctx.getIdentificationNumber()?.length == 11,
                                            check: _ctx.identificationNumberIsValid === true,
                                            'r-input-error': _ctx.identificationNumberIsValid === false,
                                        }]),
                                    "data-maska": _ctx.getIbanDynamicMask(),
                                    "data-maska-tokens": "Z:[a-zA-Z0-9]",
                                    placeholder: "00000000000"
                                  }, null, 10, _hoisted_28), [
                                    [_directive_maska, void 0, _ctx.identificationNumberMaskaOptions]
                                  ]),
                                  _createElementVNode("span", {
                                    class: _normalizeClass(["hint", { 'hint-error': _ctx.identificationNumberIsValid === false }])
                                  }, _toDisplayString(_ctx.identificationNumberHintMessage), 3)
                                ]))
                              : (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.IbanNumber])
                                ? (_openBlock(), _createElementBlock("label", _hoisted_29, [
                                    _withDirectives(_createElementVNode("input", {
                                      class: _normalizeClass(["r-input", {
                                            'r-input-error': _ctx.ibanNumberIsValid === false,
                                        }]),
                                      "data-maska": _ctx.getIbanDynamicMask(),
                                      placeholder: _ctx.getIbanDynamicPlaceholder()
                                    }, null, 10, _hoisted_30), [
                                      [_directive_maska, void 0, _ctx.ibanNumberMaskaOptions]
                                    ]),
                                    _createElementVNode("span", {
                                      class: _normalizeClass(["hint", { 'hint-error': _ctx.ibanNumberIsValid === false }])
                                    }, _toDisplayString(_ctx.ibanNumberHintMessage), 3),
                                    (_ctx.ibanNumberIsValid === true)
                                      ? (_openBlock(), _createElementBlock("img", _hoisted_31))
                                      : _createCommentVNode("", true)
                                  ]))
                                : (item.requiredField == _ctx.RequiredField[_ctx.RequiredField.MobilePhone])
                                  ? (_openBlock(), _createElementBlock("label", _hoisted_32, [
                                      _createElementVNode("input", {
                                        onKeypress: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMobilePhoneInput && _ctx.handleMobilePhoneInput(...args))),
                                        onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMobilePhoneChange && _ctx.handleMobilePhoneChange(...args))),
                                        onPaste: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                                        maxlength: "16",
                                        class: _normalizeClass(["r-input", {
                                            'r-input-error': _ctx.mobilePhoneIsValid === false,
                                        }])
                                      }, null, 34),
                                      _createElementVNode("span", {
                                        class: _normalizeClass(["hint", { 'hint-error': _ctx.mobilePhoneIsValid === false }])
                                      }, _toDisplayString(_ctx.mobilePhoneHintMessage), 3)
                                    ]))
                                  : (_openBlock(), _createElementBlock("label", _hoisted_33, [
                                      _createElementVNode("input", {
                                        class: "r-input",
                                        placeholder: item.requiredField
                                      }, null, 8, _hoisted_34)
                                    ]))
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.getTranslation("TermsAndConditionsTitle")), 1)
                ]),
                (_ctx.data?.orderDetails?.paymentMethod?.legalInfo?.codeOfConduct)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                      _createElementVNode("p", _hoisted_39, [
                        _createElementVNode("span", {
                          innerHTML: _ctx.data?.orderDetails?.paymentMethod?.legalInfo?.codeOfConduct
                        }, null, 8, _hoisted_40)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-12", {
                                    'mt-2': _ctx.getIsRequiredCheckbox(),
                                }])
                }, [
                  _withDirectives(_createVNode(_component_r_checkbox, {
                    id: "termsCheck",
                    onClick: _ctx.handleChecked
                  }, null, 8, ["onClick"]), [
                    [_vShow, _ctx.getIsRequiredCheckbox()]
                  ]),
                  _withDirectives(_createElementVNode("div", {
                    class: "row checkbox-info",
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleLabelChecked && _ctx.handleLabelChecked(...args)))
                  }, [
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.getTranslation("TermsAndConditions")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("p", _hoisted_44, [
                        _createElementVNode("span", {
                          innerHTML: _ctx.data?.orderDetails?.paymentMethod?.legalInfo?.text
                        }, null, 8, _hoisted_45)
                      ])
                    ])
                  ], 512), [
                    [_vShow, _ctx.getIsRequiredCheckbox()]
                  ])
                ], 2),
                _withDirectives(_createElementVNode("div", _hoisted_46, [
                  _createElementVNode("div", _hoisted_47, [
                    _createElementVNode("div", _hoisted_48, [
                      _createElementVNode("p", _hoisted_49, [
                        _createElementVNode("span", {
                          innerHTML: _ctx.data?.orderDetails?.paymentMethod?.legalInfo?.text
                        }, null, 8, _hoisted_50)
                      ])
                    ])
                  ])
                ], 512), [
                  [_vShow, !_ctx.getIsRequiredCheckbox()]
                ]),
                _createElementVNode("div", _hoisted_51, [
                  _createVNode(_component_r_button, {
                    class: "w-100 mb-2",
                    variant: "primary",
                    expanded: "",
                    disabled: !_ctx.canContinue,
                    onClick: _ctx.handleContinue
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.continueButtonTranslation()), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]),
                  _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_component_r_button, {
                    class: "w-100",
                    variant: "text",
                    href: "#modal-content",
                    onClick: _ctx.handleCancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTranslation("CancelPayment")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("div", _hoisted_53, [
                _createElementVNode("div", _hoisted_54, [
                  _createElementVNode("p", _hoisted_55, _toDisplayString(_ctx.data.orderDetails.merchantName), 1),
                  _createElementVNode("p", _hoisted_56, _toDisplayString(_ctx.currency(_ctx.data.orderDetails.orderDetail.totalAmount)), 1),
                  _createVNode(_component_r_accordion, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_r_accordion_item, {
                        header: _ctx.getTranslation('OrderDetailsTitle')
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.orderDetails.orderDetail.items, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "row",
                              key: index
                            }, [
                              _createElementVNode("div", _hoisted_57, [
                                _createElementVNode("div", _hoisted_58, [
                                  _createElementVNode("div", _hoisted_59, [
                                    _createElementVNode("p", null, _toDisplayString(item.quantity) + "x " + _toDisplayString(item.name), 1),
                                    _createElementVNode("p", null, _toDisplayString(item.description), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_60, [
                                    _createElementVNode("p", null, _toDisplayString(_ctx.currency(item.grossUnitPrice)), 1)
                                  ])
                                ]),
                                _cache[8] || (_cache[8] = _createElementVNode("hr", { class: "m-0 pb-4" }, null, -1))
                              ])
                            ]))
                          }), 128)),
                          (_ctx.data.orderDetails.provider == _ctx.Provider[_ctx.Provider.LightspeedCSeries])
                            ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                                _createElementVNode("div", _hoisted_62, [
                                  _createElementVNode("div", _hoisted_63, [
                                    _createElementVNode("div", _hoisted_64, [
                                      _createElementVNode("p", null, _toDisplayString(_ctx.getTranslation("DeliveryFee")), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_65, [
                                      _createElementVNode("p", null, _toDisplayString(_ctx.currency(_ctx.data.orderDetails.shippingGrossPrice)), 1)
                                    ])
                                  ]),
                                  _cache[9] || (_cache[9] = _createElementVNode("hr", { class: "m-0 pb-4" }, null, -1))
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.showAddresses())
                            ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                                _createElementVNode("div", _hoisted_67, [
                                  _createElementVNode("p", _hoisted_68, _toDisplayString(_ctx.getTranslation("ShippingAddress")), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.shippingCustomer.firstName) + " " + _toDisplayString(_ctx.data.orderDetails.shippingCustomer.lastName), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.street) + " " + _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.streetNumber), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.postalCode) + " " + _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.countryCode), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.postalPlace), 1)
                                ]),
                                _createElementVNode("div", _hoisted_69, [
                                  _createElementVNode("p", _hoisted_70, _toDisplayString(_ctx.getTranslation("BillingAddress")), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.billingCustomer.firstName) + " " + _toDisplayString(_ctx.data.orderDetails.billingCustomer.lastName), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.street) + " " + _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.streetNumber), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.postalCode) + " " + _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.countryCode), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.postalPlace), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["header"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("footer", null, [
        _createElementVNode("div", _hoisted_71, [
          _createElementVNode("div", _hoisted_72, [
            _createElementVNode("div", _hoisted_73, [
              _createElementVNode("p", _hoisted_74, _toDisplayString(_ctx.getTranslation("Powered")), 1),
              _cache[10] || (_cache[10] = _createElementVNode("img", {
                alt: "Riverty logo",
                width: "143",
                src: "https://cdn.riverty.design/logo/riverty-logo-green.svg"
              }, null, -1))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_ModalComponent, null, {
      default: _withCtx(() => [
        (!_ctx.showErrorPaymentMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
              _createElementVNode("div", _hoisted_76, [
                _createElementVNode("div", _hoisted_77, [
                  _createElementVNode("p", _hoisted_78, _toDisplayString(_ctx.getTranslation("TitleCancelation")), 1),
                  _createElementVNode("p", {
                    class: "r-font-size-400 mb-4 pb-3",
                    innerHTML: _ctx.getTranslation('DescriptionCancelation', [_ctx.data.orderDetails.merchantName])
                  }, null, 8, _hoisted_79)
                ]),
                _createElementVNode("div", _hoisted_80, [
                  _createVNode(_component_r_button, {
                    class: "w-100",
                    variant: "primary",
                    expanded: "",
                    onClick: _ctx.handleConfirmCancelation
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTranslation("ConfirmCancelation")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_component_r_button, {
                    variant: "text",
                    onClick: _ctx.handleContinueWith
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTranslation("ContinueWith")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]))
          : (_ctx.showErrorPaymentMessage && !_ctx.errorPaymentMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                _createElementVNode("div", _hoisted_82, [
                  _createElementVNode("div", _hoisted_83, [
                    _cache[12] || (_cache[12] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
                    _createElementVNode("p", _hoisted_84, _toDisplayString(_ctx.getTranslation("PaymentErrorTitle")), 1),
                    _createElementVNode("p", _hoisted_85, _toDisplayString(_ctx.getTranslation("PaymentErrorSubTitle")), 1),
                    _createElementVNode("p", {
                      class: "r-font-size-400 mb-4 pb-3",
                      innerHTML: _ctx.getTranslation('PaymentErrorDescription')
                    }, null, 8, _hoisted_86),
                    _createVNode(_component_r_button, {
                      variant: "primary",
                      expanded: "",
                      onClick: _ctx.handleContinueWith
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTranslation("ReturnTo", [_ctx.data.orderDetails.merchantName ?? _ctx.getTranslation("GenericReturnToMerchant")])), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_87, [
                _createElementVNode("div", _hoisted_88, [
                  _createElementVNode("div", _hoisted_89, [
                    _createElementVNode("p", _hoisted_90, _toDisplayString(_ctx.getTranslation("PaymentCustomErrorTitle")), 1),
                    _createElementVNode("p", {
                      class: "r-font-size-400 mb-4 pb-3",
                      innerHTML: _ctx.getTranslation('PaymentCustomErrorSubTitle', [_ctx.errorPaymentMessage])
                    }, null, 8, _hoisted_91),
                    _createVNode(_component_r_button, {
                      variant: "primary",
                      expanded: "",
                      onClick: _ctx.handleContinueWith
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTranslation("ReturnTo", [_ctx.data.orderDetails.merchantName ?? _ctx.getTranslation("GenericReturnToMerchant")])), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ])
              ]))
      ]),
      _: 1
    })
  ], 64))
}