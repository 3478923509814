import { MerchantSettingsApiKeyValue, MerchantSettings, TextResourceList } from "@/entities";
import { CountryCode, ErrorFieldEnum } from "@/enums";
import { getTextResources } from "tests/entities";
import { get, post } from "@/utils/httpRequest";
import { Telemetry } from "@/utils/telemetry";
import { loading } from "@/utils/common";
import { defineComponent } from "vue";

export default defineComponent({
    name: "SettingsView",
    data: function () {
        return {
            countryCode: CountryCode,
            elementUpdated: false,
            errorFieldEnum: ErrorFieldEnum,
            merchantSettings: new MerchantSettings(),
            showMessage: false,
            changedFields: Array<CountryCode>(),
            errorFields: Array<ErrorFieldEnum>(),
            textResourceList: new TextResourceList(),
            minAmountFilterMaskaOptions: {
                postProcess: (val: string) => this.handleMinMaxValidation(val, true),
            },
            maxAmountFilterMaskaOptions: {
                postProcess: (val: string) => this.handleMinMaxValidation(val, false),
            },
        };
    },
    async mounted() {
        Telemetry.pageLoaded(`${this.$options.name}`);
        this.getData();
    },
    methods: {
        async getData(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            loading();
            this.merchantSettings = await get("application/settings", fetchFromTest, httpsAgent);
            this.textResourceList = (await this.getTextResources(this.merchantSettings.languageCode, fetchFromTest, httpsAgent)) as TextResourceList;
            loading();
        },
        async getTextResources(languageCode: string, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = [
                "SettingsHeader",
                "SettingsSuccessMessage",
                "SettingsTitle",
                "SettingsDescription",
                "SettingsInputTitle",
                "SettingsSave",
                "SettingsClose",
                "SettingsNetherlandsHint",
                "SettingsNetherlandsHintError",
                "SettingsBelgiumHint",
                "SettingsBelgiumHintError",
                "SettingsGermanHint",
                "SettingsGermanHintError",
                "SettingsAustriaHint",
                "SettingsAustriaHintError",
                "SettingsMinMaxAmountFilterTitle",
                "SettingsMinMaxAmountFilterHint",
                "SettingsMinAmountFilterLabel",
                "SettingsMinAmountFilterHint",
                "SettingsMinAmountFilterHintError",
                "SettingsMaxAmountFilterLabel",
                "SettingsMaxAmountFilterHint",
                "SettingsMaxAmountFilterHintError",
            ];

            const response = await post(
                "text-resources/list",
                {
                    languageCode,
                    sysnameList,
                },
                fetchFromTest,
                httpsAgent
            );

            if (!fetchFromTest) return response;

            return { obj: response, list: sysnameList };
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            let value = this.textResourceList?.textResourceItems.find((x) => x.sysName == sysName)?.value;

            replace.forEach((item: string, index: number) => {
                value = value?.replace(`{${index}}`, item);
            });

            return value ?? "";
        },
        hasChanded(countryCode: CountryCode) {
            return this.changedFields.find((x) => x == countryCode) != null;
        },
        hasError(errorFieldEnum: ErrorFieldEnum) {
            return this.errorFields.find((x) => x == errorFieldEnum) != null;
        },
        handleOnFocus(countryCode: CountryCode) {
            const obj = this.getApiKey(countryCode);
            if (obj) {
                obj.apiKey = "";
            }

            const exists = this.changedFields.find((x) => x == countryCode);
            if (!exists) {
                this.changedFields.push(countryCode);
            }
        },
        handleOnInput(countryCode: CountryCode, event: InputEvent) {
            const obj = this.getApiKey(countryCode);

            if (obj) {
                obj.apiKey = (event.target as HTMLInputElement).value;
            }
        },
        getApiKey(countryCode: CountryCode): MerchantSettingsApiKeyValue {
            let obj = this.merchantSettings.merchantSettingsApiKeyValues.find((x) => x.country == CountryCode[countryCode]);
            if (!obj) {
                obj = new MerchantSettingsApiKeyValue().init(countryCode);
                this.merchantSettings.merchantSettingsApiKeyValues.push(obj);
            }
            return obj;
        },
        handleClose() {
            Telemetry.pageRedirected(`${this.$options.name}`);
            window.location.href = this.merchantSettings.redirectUrl;
        },
        handleCheckboxChange() {
            this.merchantSettings.enableMinMaxAmountFilter = !this.merchantSettings.enableMinMaxAmountFilter;
        },
        handleMinMaxValidation(val: string, isMinFilter: boolean) {
            if (isMinFilter && val != this.merchantSettings.minAmountFilter) {
                this.merchantSettings.minAmountFilter = val;
                this.elementUpdated = true;
            } else if (!isMinFilter && val != this.merchantSettings.maxAmountFilter) {
                this.merchantSettings.maxAmountFilter = val;
                this.elementUpdated = true;
            }

            return val;
        },
        validatedAndParseToFload(value: string | null) {
            if (value == null) {
                return null;
            }
            return parseFloat(value.replace(/\s+/g, "").replace(",", "."));
        },
        async handleSubmit() {
            loading();
            this.errorFields = [];
            const body = {
                merchantSettingsApiKeyValues: Array<MerchantSettingsApiKeyValue>(),
                enableMinMaxAmountFilter: this.merchantSettings.enableMinMaxAmountFilter,
                minAmountFilter: this.validatedAndParseToFload(this.merchantSettings.minAmountFilter),
                maxAmountFilter: this.validatedAndParseToFload(this.merchantSettings.maxAmountFilter),
            };

            this.changedFields.forEach((field) => {
                if (field == CountryCode.NL) {
                    body.merchantSettingsApiKeyValues.push(this.getApiKey(CountryCode.NL));
                }
                if (field == CountryCode.BE) {
                    body.merchantSettingsApiKeyValues.push(this.getApiKey(CountryCode.BE));
                }
                if (field == CountryCode.DE) {
                    body.merchantSettingsApiKeyValues.push(this.getApiKey(CountryCode.DE));
                }
                if (field == CountryCode.AT) {
                    body.merchantSettingsApiKeyValues.push(this.getApiKey(CountryCode.AT));
                }
            });

            const response: Response = await post("application/settings", body);
            if (response.status === 200) {
                this.errorFields = [];
                this.changedFields = [];
                this.showMessage = true;

                setTimeout(() => {
                    this.showMessage = false;
                    this.elementUpdated = false;
                }, 3000);

                await this.getData();
            } else {
                this.errorFields = Array.isArray(response) ? response : [];
                this.changedFields = [];

                this.errorFields.forEach((errorField) => {
                    const errorFieldStr = ErrorFieldEnum[errorField] as keyof typeof ErrorFieldEnum;
                    const countryCode = CountryCode[errorFieldStr as keyof typeof CountryCode];

                    const field = this.getApiKey(countryCode);

                    if (field) {
                        field.apiKey = "";
                    }
                });
            }
            loading();
        },
    },
});
