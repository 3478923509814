import { currency, getCookie, loading, normalizeJsonToLowerCase, normalizeText, setCookie, translation } from "@/utils/common";
import { OrderInformation, ProfileTrackingIdConfig, RequiredFieldItem, TextResourceList } from "@/entities";
import { RequiredField, LanguageCode, OrderStatus, CountryCode, Provider, PaymentType } from "@/enums";
import { ModalComponent, PayInXComponent, FixedInstallmentsComponent } from "@/components";
import { getTextResources } from "tests/entities";
import { get, post } from "@/utils/httpRequest";
import { Telemetry } from "@/utils/telemetry";
import { defineComponent } from "vue";
import { MaskaDetail } from "maska";
import { v4 as uuidv4 } from "uuid";

declare global {
    interface Window {
        _itt: unknown;
    }
}

export default defineComponent({
    name: "PayView",
    components: {
        ModalComponent,
        PayInXComponent,
        FixedInstallmentsComponent,
    },
    props: {
        language: {
            type: String,
            required: true,
        },
        orderKey: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            data: new OrderInformation(),
            textResourceList: new TextResourceList(),
            birthDateIsValid: false,
            birthDateInputError: false,
            birthDateHintMessage: "",
            birthDateMaskaOptions: {
                onMaska: (detail: MaskaDetail) => (detail.completed ? this.handleBirthDateComplete(detail) : null),
                postProcess: (val: string) => this.handleBirthDateValidation(val),
            },
            companyNameHintMessage: "",
            identificationNumberIsValid: null as unknown as boolean,
            identificationNumberHintMessage: "",
            identificationNumberMaskaOptions: {
                onMaska: (detail: MaskaDetail) => (detail.completed ? this.handleIdentificationNumberComplete(detail) : null),
                postProcess: (val: string) => this.handleIdentificationValidation(val),
            },
            ibanNumberIsValid: null as unknown as boolean,
            ibanNumberHintMessage: "",
            ibanNumberMaskaOptions: {
                onMaska: (detail: MaskaDetail) => (detail.completed ? this.handleIbanNumberComplete(detail) : null),
                postProcess: (val: string) => this.handleIbanValidation(val),
            },
            mobilePhoneIsValid: null as unknown as boolean,
            mobilePhoneHintMessage: "",
            canContinue: false,
            requiredFields: Array<RequiredFieldItem>(),
            oldRequiredFields: Array<RequiredFieldItem>(),
            showErrorPaymentMessage: false,
            errorPaymentMessage: null as unknown as string,
            merchantRedirectUrl: "",
            RequiredField,
            ipAddress: "0.0.0.0",
            sessionId: "",
            Provider,
            paymentType: PaymentType,
            idempotencyKey: "",
            retryAttemptCount: 0,
            isRedirecting: false,
        };
    },
    beforeCreate() {
        Telemetry.pageOpened(`${this.$options.name}`, this.orderKey);
        localStorage.clear();

        addEventListener("beforeunload", () => {
            if (!this.isRedirecting && this.canContinue) {
                Telemetry.pageClosed(`${this.$options.name}`, this.orderKey);
            }
        });

        const token: string = this.$route.query.token as string;
        const updatedQueryParams = { ...this.$route.query };
        delete updatedQueryParams.token;
        this.$router.replace({ query: updatedQueryParams });

        if (token) {
            setCookie("token", encodeURIComponent(token));
        } else {
            const existingToken = getCookie("token");
            if (!existingToken) {
                this.isRedirecting = true;
                Telemetry.pageRedirected(`${this.$options.name}`, this.orderKey);
                window.location.href = "/error";
            }
        }
    },
    mounted() {
        Telemetry.pageLoaded(`${this.$options.name}`, this.orderKey);

        addEventListener("click", (e) => {
            const target = e.target as HTMLLinkElement;
            if (target.nodeName == "A") {
                target.setAttribute("target", "_blank");
            }
        });

        this.getIpAddress();
        this.getData();
    },
    methods: {
        currency(value: number) {
            switch (this.data.orderDetails.country) {
                case CountryCode[CountryCode.CH]:
                    return currency(value, "CHF");
                case CountryCode[CountryCode.NO]:
                    return currency(value, "NOK");
                case CountryCode[CountryCode.DK]:
                    return currency(value, "DKK");
                case CountryCode[CountryCode.SE]:
                    return currency(value, "SEK");
                default:
                    return currency(value);
            }
        },
        getIpAddress() {
            fetch("https://api.ipify.org?format=json")
                .then((x) => x.json())
                .then(({ ip }) => {
                    this.ipAddress = ip;
                });
        },
        async getData() {
            loading();
            try {
                this.textResourceList = (await this.getTextResources(this.language)) as TextResourceList;
                const resp = await get(`authorize-payment/order/${this.orderKey}`);

                if (resp.status != 200) {
                    this.showErrorPaymentMessage = true;
                    document.body.style.overflow = "hidden";
                    window.location.href = "#modal-content";
                    this.merchantRedirectUrl = resp.urlRedirect;
                    this.data.orderDetails.merchantName = resp.merchantName;
                    this.errorPaymentMessage = resp.errorMessages;
                } else {
                    this.data = resp;

                    this.data.orderDetails.requiredFields.forEach((requiredField) => {
                        this.requiredFields.push(new RequiredFieldItem(requiredField));
                    });
                }
                this.canContinue = this.data.orderDetails.requiredFields == null || this.data.orderDetails.requiredFields.length == 0;

                if (this.data.orderDetails.orderStatus != OrderStatus[OrderStatus.New]) {
                    this.redirectTo(this.data.redirectUrl);
                }

                this.profileTracking();

                // handling special characters
                this.data.orderDetails.paymentMethod.title = normalizeText(this.data.orderDetails.paymentMethod.title);
                this.data.orderDetails.paymentMethod.method = normalizeText(this.data.orderDetails.paymentMethod.method);
                this.data.orderDetails.paymentMethod.legalInfo.text = normalizeText(this.data.orderDetails.paymentMethod.legalInfo.text);

                // update messages
                this.birthDateHintMessage = this.getTranslation("InputBirthDateHint");
                this.companyNameHintMessage = this.getTranslation("InputCompanyNameHint");
                this.identificationNumberHintMessage = this.getTranslation("InputIdentificationNumberHint");
                this.ibanNumberHintMessage = this.getTranslation("InputIbanNumberHint");
                this.mobilePhoneHintMessage = this.getTranslation("InputMobilePhoneHint");

                // searching for idempotencyKey and retryAttemptCount
                this.idempotencyKey = localStorage.getItem(`idempotencyKey-${this.orderKey}`) ?? this.getOrSetIdempotencyKey();
                this.retryAttemptCount = parseInt(localStorage.getItem(`retryAttemptCount-${this.orderKey}`) ?? this.getOrSetRetryAttemptCount());
            } catch (e) {
                if (this.data.redirectUrl) {
                    this.redirectTo(this.data.redirectUrl);
                } else {
                    this.showErrorPaymentMessage = true;
                }
            } finally {
                loading();
            }
        },
        getOrSetIdempotencyKey() {
            const newKey = uuidv4();
            localStorage.setItem(`idempotencyKey-${this.orderKey}`, newKey);
            return newKey;
        },
        getOrSetRetryAttemptCount() {
            const newCount = "0";
            localStorage.setItem(`retryAttemptCount-${this.orderKey}`, newCount);
            return newCount;
        },
        async profileTracking() {
            if (this.data.orderDetails.country == CountryCode[CountryCode.AT] || this.data.orderDetails.country == CountryCode[CountryCode.DE]) {
                if (this.data.profileTrackingId.length > 0) {
                    this.sessionId = sessionStorage.getItem("sessionId") ?? "";
                    if (this.sessionId.length == 0) {
                        this.sessionId = uuidv4();
                        sessionStorage.setItem("sessionId", this.sessionId);
                    }

                    const config = new ProfileTrackingIdConfig(this.data.profileTrackingId, this.sessionId, window.location.pathname);
                    window._itt = config;

                    const script = document.createElement("script");
                    script.src = `https://${config.h}/container/${config.c}?page=${config.u}`;
                    script.async = true;
                    script.onload = function () {
                        (this as HTMLElement).remove();
                    };
                    (document.head || document.documentElement).appendChild(script);
                } else {
                    this.isRedirecting = true;
                    window.location.href = "/error";
                }
            }
        },
        async getTextResources(languageCode: string, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = [
                "CheckoutTitle",
                "AccordionTitle",
                "PaymentTitle",
                "Installments",
                "WithinDays",
                "VerifyTitle",
                "VerifyDescription",
                "InputBirthDateLabel",
                "InputBirthDateHint",
                "WrongBirthDate",
                "InputCompanyNameLabel",
                "InputCompanyNameHint",
                "InputIdentificationNumberLabel",
                "InputIdentificationNumberHint",
                "InputIbanNumberLabel",
                "InputIbanNumberHint",
                "InputSSNNumberLabel",
                "TermsAndConditions",
                "GeneralTermsAndConditions",
                "VerifyPay",
                "CancelPayment",
                "Powered",
                "DeliveryFee",
                "ShippingAddress",
                "BillingAddress",
                "MinimumAge",
                "TitleCancelation",
                "DescriptionCancelation",
                "ConfirmCancelation",
                "ContinueWith",
                "TitleSomethingWentWrong",
                "DescriptionSomethingWentWrong",
                "AditionalDescriptionSomethingWentWrong",
                "ReturnTo",
                "PaymentErrorTitle",
                "PaymentErrorSubTitle",
                "PaymentErrorDescription",
                "PaymentCustomErrorTitle",
                "PaymentCustomErrorSubTitle",
                "InputMobilePhoneLabel",
                "InputMobilePhoneHint",
                "GenericReturnToMerchant",
                "PayByInvoice",
                "PayBy3",
                "PayByDirectDebit",
                "PayByConsolidatedInvoice",
                "PayByFixedInstallments",
                "TermsAndConditionsTitle",
                "OrderDetailsTitle",
                "BackToListButton",
                "PayInInstallments",
                "OrderAmount",
                "InterestPA",
                "TotalAmountDue",
                "NumberOfInstallments",
                "InstallmentAmount",
                "InterestInformation",
                "LastInstallmentAmountDue",
                "MandatoryInformationLinks",
            ];

            const response = await post(
                "text-resources/list",
                {
                    languageCode,
                    sysnameList,
                },
                fetchFromTest,
                httpsAgent
            );

            if (!fetchFromTest) return response;

            return { obj: response, list: sysnameList };
        },
        handleInput(e: InputEvent, item: RequiredFieldItem) {
            item.value = (e.target as HTMLInputElement).value;
            this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;
        },
        handleBirthDateValidation(val: string) {
            this.birthDateHintMessage = this.getTranslation("InputBirthDateHint");
            this.birthDateInputError = false;
            this.canContinue = false;
            this.birthDateIsValid = false;

            this.dateAndAgeValidationReturningBirthDate(val);

            return val;
        },
        handleBirthDateComplete(detail: MaskaDetail) {
            const birthDate = this.dateAndAgeValidationReturningBirthDate(detail.masked);
            const birthDateRequiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.BirthDate]);

            if (!this.birthDateInputError && birthDateRequiredField) {
                this.birthDateIsValid = true;
                birthDateRequiredField.value = birthDate;
                this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;
            }
        },
        dateAndAgeValidationReturningBirthDate(date: string) {
            const tempVal = date.replaceAll("/", "");
            const day = Number.parseInt(tempVal.substring(0, 2));
            const month = Number.parseInt(tempVal.substring(2, 4));
            const year = Number.parseInt(tempVal.substring(4, 8));

            if ((day > 31 || month > 12 || year > new Date().getFullYear() || year <= 1900) && date.length == 10) {
                this.birthDateInputError = true;
                this.birthDateHintMessage = this.getTranslation("WrongBirthDate");
            }

            const birthDate = `${year}-${month > 9 ? month : "0" + month}-${day > 9 ? day : "0" + day}`;
            this.ageValidation(birthDate);

            return birthDate;
        },
        ageValidation(date: string) {
            if (date.length == 10) {
                const birthDateTime = new Date(date).getTime();
                const currentTime = new Date().getTime();
                const difference = currentTime - birthDateTime;
                const age = difference / (1000 * 60 * 60 * 24 * 365);

                if (age < 18) {
                    this.birthDateInputError = true;
                    this.birthDateHintMessage = this.getTranslation("MinimumAge");
                }
            }
        },
        handleCompanyNameInput(e: InputEvent, item: RequiredFieldItem) {
            item.value = (e.target as HTMLInputElement).value;
            this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;
        },
        handleIdentificationValidation(val: string) {
            this.identificationNumberHintMessage = this.getTranslation("InputIdentificationNumberHint");
            this.canContinue = false;
            this.identificationNumberIsValid = false;

            if (val.length) {
                switch (this.data.orderDetails.country) {
                    case CountryCode[CountryCode.SE]:
                    case CountryCode[CountryCode.DK]:
                        this.identificationNumberIsValid = val.length === 10;
                        break;
                    default:
                        this.identificationNumberIsValid = val.length === 11;
                        break;
                }
            }
            return val;
        },
        handleIdentificationNumberComplete(detail: MaskaDetail) {
            const requiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.IdentificationNumber]);

            if (this.identificationNumberIsValid && requiredField) {
                requiredField.value = detail.masked;
                this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;
            }
        },
        handleIbanValidation(val: string) {
            this.ibanNumberHintMessage = this.getTranslation("InputIbanNumberHint");
            this.canContinue = false;
            this.ibanNumberIsValid = false;

            if (val.length) {
                switch (this.data.orderDetails.country) {
                    case CountryCode[CountryCode.AT]:
                        this.ibanNumberIsValid = val.length === 24;
                        break;
                    case CountryCode[CountryCode.BE]:
                    case CountryCode[CountryCode.NO]:
                        this.ibanNumberIsValid = val.length === 19;
                        break;
                    case CountryCode[CountryCode.CH]:
                        this.ibanNumberIsValid = val.length === 26;
                        break;
                    case CountryCode[CountryCode.DE]:
                        this.ibanNumberIsValid = val.length === 27;
                        break;
                    case CountryCode[CountryCode.SE]:
                        this.ibanNumberIsValid = val.length === 29;
                        break;
                    default:
                        this.ibanNumberIsValid = val.length === 22;
                        break;
                }
            }
            return val;
        },
        handleIbanNumberComplete(detail: MaskaDetail) {
            const requiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.IbanNumber]);

            if (this.ibanNumberIsValid && requiredField) {
                this.ibanNumberIsValid = true;
                requiredField.value = detail.masked;
                this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;
            }
        },
        handleMobilePhoneInput(e: KeyboardEvent): boolean {
            //only allow characters that can be part of a valid phone nr: numbers, +, -, (, ) and whitespace)
            const regex = /^\+?[0-9\-\s()]*$/;
            const target = e.target as HTMLInputElement;

            if (!regex.test(e.key) || (e.key == "+" && (target.value.includes("+") || target.value.length > 0))) {
                //don't allow that character
                e.preventDefault();
                return false;
            }
            return true;
        },
        handleMobilePhoneChange(e: Event) {
            const target = e.target as HTMLInputElement;
            this.mobilePhoneIsValid = false;

            const str = target.value.replace(/\s/g, ""); //remove whitespaces

            if (str.length) {
                const requiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.MobilePhone]);

                this.mobilePhoneIsValid = str.length > 0 && str.length <= 20;

                if (this.mobilePhoneIsValid && requiredField) {
                    requiredField.value = str;
                }
            }

            this.canContinue = this.mobilePhoneIsValid && this.requiredFields.filter((x) => x.value == "").length === 0;
        },
        handleCancel() {
            this.showErrorPaymentMessage = false;
            document.body.style.overflow = "hidden";
        },
        async handleConfirmCancelation() {
            loading();
            const resp = await get(`authorize-payment/cancel-authorize/${this.orderKey}`);
            this.redirectTo(resp.urlRedirect);
        },
        handleContinueWith() {
            if (this.showErrorPaymentMessage) {
                this.redirectTo(this.merchantRedirectUrl);
            } else {
                window.location.href = "#";
                setTimeout(() => {
                    this.showErrorPaymentMessage = false;
                }, 200);
            }
        },
        handleChecked() {
            const item = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.TermsAndConditions]);
            if (item) {
                item.value = `${!(document.querySelector("r-checkbox") as HTMLRCheckboxElement).checked ? "True" : ""}`;
                this.canContinue = this.requiredFields.filter((x) => x.value == "").length === 0;
            }
        },
        handleLabelChecked() {
            const checkbox = document.getElementById("termsCheck");
            checkbox?.click();
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList.textResourceItems, sysName, replace);
        },
        getIsRequiredCheckbox(): boolean {
            return this.data.orderDetails.requiredFields?.find((x) => x == RequiredField[RequiredField.TermsAndConditions]) != null;
        },
        getPayInXObj() {
            if (this.data.orderDetails.paymentMethod.additionalData) {
                return JSON.parse(normalizeJsonToLowerCase(this.data.orderDetails.paymentMethod.additionalData));
            }
        },
        handleContinueVerification() {
            this.redirectTo(this.merchantRedirectUrl);
        },
        continueButtonTranslation() {
            let translation = this.getTranslation("VerifyPay");

            switch (this.data?.orderDetails?.paymentMethod?.type?.toLowerCase()) {
                case "invoice":
                    translation = this.getTranslation("PayByInvoice");
                    break;
                case "payinx":
                    translation = this.getTranslation("PayBy3");
                    break;
                case "directdebit":
                    translation = this.getTranslation("PayByDirectDebit");
                    break;
                case "consolidatedinvoice":
                    translation = this.getTranslation("PayByConsolidatedInvoice");
                    break;
                case "installment":
                    translation = this.getTranslation("PayByFixedInstallments");
                    break;
            }

            if (this.data?.orderDetails?.paymentMethod?.isDirectDebit && this.data?.orderDetails?.paymentMethod?.type?.toLowerCase() != "installment") {
                translation = this.getTranslation("PayByDirectDebit");
            }

            return translation;
        },
        async handleContinue() {
            if (this.canContinue) {
                loading();
                this.verifyRequiredFields();
                this.canContinue = false;
                this.errorPaymentMessage = null as unknown as string;

                const fixedInstallmentsComponent = this.$refs.fixedInstallmentsComponent as typeof FixedInstallmentsComponent;
                let selectedInstallment = null;
                if (fixedInstallmentsComponent) {
                    selectedInstallment = fixedInstallmentsComponent.selectedInstallmentProfile;
                }

                const body = {
                    ipAddress: this.ipAddress,
                    requiredFieldItems: this.requiredFields,
                    idempotencyKey: this.idempotencyKey,
                    retryAttemptCount: `${this.retryAttemptCount}`,
                    sessionProfileTrackingId: this.sessionId,
                    installment: selectedInstallment,
                };

                const resp = await post(`authorize-payment/authorize/${this.orderKey}`, body);

                if (resp.status == 408) {
                    this.verifyRetry();
                } else if (resp.status != 200 && !resp.isScaRequired) {
                    this.showErrorPaymentMessage = true;
                    window.location.href = "#modal-content";
                    document.body.style.overflow = "hidden";
                    this.errorPaymentMessage = resp.errorMessages;
                    this.merchantRedirectUrl = resp.urlRedirect ?? this.data.redirectUrl;
                } else {
                    if (resp?.newToken?.length > 0) {
                        setCookie("token", encodeURIComponent(resp.newToken));
                    }

                    this.redirectTo(resp.urlRedirect);
                }
                loading();
            }
        },
        redirectTo(url: string) {
            this.isRedirecting = true;
            Telemetry.pageRedirected(`${this.$options.name}`, this.orderKey);
            window.location.replace("//" + url.replace(/^https?:\/\//, ""));
        },
        getBirthDatePlaceholder() {
            switch (this.language) {
                case LanguageCode[LanguageCode.NL].toLowerCase():
                    return "DD/MM/JJJJ";
                case LanguageCode[LanguageCode.DE].toLowerCase():
                    return "TT/MM/JJJJ";
                default:
                    return "DD/MM/YYYY";
            }
        },
        getIdentificationNumberDynamicMask() {
            switch (this.data.orderDetails.country) {
                case CountryCode[CountryCode.SE]:
                case CountryCode[CountryCode.DK]:
                    return "##########";
                case CountryCode[CountryCode.FI]:
                    return "######-###Z";
                default:
                    return "###########";
            }
        },
        getIdentificationNumber() {
            const requiredField = this.requiredFields.find((x) => x.requiredField == RequiredField[RequiredField.IdentificationNumber]);
            return requiredField?.value;
        },
        showAddresses() {
            switch (this.data.orderDetails.country) {
                case CountryCode[CountryCode.SE]:
                case CountryCode[CountryCode.NO]:
                case CountryCode[CountryCode.DK]:
                case CountryCode[CountryCode.FI]:
                    return false;
                default:
                    return true;
            }
        },
        getIbanDynamicMask() {
            switch (this.data.orderDetails.country) {
                case CountryCode[CountryCode.AT]:
                    return "AT## #### #### #### ####";
                case CountryCode[CountryCode.BE]:
                    return "BE## #### #### ####";
                case CountryCode[CountryCode.CH]:
                    return "CH## #### #@@@ @@@@ @@@@ @";
                case CountryCode[CountryCode.DE]:
                    return "DE## #### #### #### #### ##";
                case CountryCode[CountryCode.DK]:
                    return "DK## #### #### #### ##";
                case CountryCode[CountryCode.FI]:
                    return "FI## #### #### #### ##";
                case CountryCode[CountryCode.NL]:
                    return "NL## @@@@ #### #### ##";
                case CountryCode[CountryCode.NO]:
                    return "NO## #### #### ####";
                case CountryCode[CountryCode.SE]:
                    return "SE## #### #### #### #### ####";
                default:
                    return "SS## @@@@ #### #### ##";
            }
        },
        getIbanDynamicPlaceholder() {
            switch (this.data.orderDetails.country) {
                case CountryCode[CountryCode.AT]:
                    return "AT00 0000 0000 0000 0000";
                case CountryCode[CountryCode.BE]:
                    return "BE00 0000 0000 0000";
                case CountryCode[CountryCode.CH]:
                    return "CH00 0000 0XXX XXXX XXXX X";
                case CountryCode[CountryCode.DE]:
                    return "DE00 0000 0000 0000 0000 00";
                case CountryCode[CountryCode.DK]:
                    return "DK00 0000 0000 0000 00";
                case CountryCode[CountryCode.FI]:
                    return "FI00 0000 0000 0000 00";
                case CountryCode[CountryCode.NL]:
                    return "NL00 XXXX 0000 0000 00";
                case CountryCode[CountryCode.NO]:
                    return "NO00 0000 0000 0000";
                case CountryCode[CountryCode.SE]:
                    return "SE00 0000 0000 0000 0000 0000";
                default:
                    return "XX00 XXXX 0000 0000 00";
            }
        },
        payInXType() {
            return PaymentType[PaymentType.PayinX];
        },
        installmentType() {
            return PaymentType[PaymentType.Installment];
        },
        handleBackToList() {
            this.isRedirecting = true;
            this.redirectTo(location.href.replace("pay", "payment-methods"));
        },
        verifyRequiredFields() {
            if (this.retryAttemptCount == 0) {
                this.oldRequiredFields = JSON.parse(JSON.stringify(this.requiredFields));
            }
        },
        verifyRetry() {
            this.retryAttemptCount++;

            if (JSON.stringify(this.requiredFields) != JSON.stringify(this.oldRequiredFields)) {
                this.idempotencyKey = uuidv4();
                this.retryAttemptCount = 0;
                localStorage.clear();
                localStorage.setItem(`idempotencyKey-${this.orderKey}`, this.idempotencyKey);
            }

            localStorage.setItem(`retryAttemptCount-${this.orderKey}`, `${this.retryAttemptCount}`);
            this.canContinue = true;
        },
    },
});
